import { writeDebug, writeErr } from '../components/common/logger';
import { errorType } from './appContextStore';
import { push } from 'react-router-redux'
import axios from 'axios';
import sha1 from 'crypto-js/sha1';
import { getLanguage, getMomentLanguage, getAuthorization, isString, getRandomValue } from './storeFunctions';

const LOCATION_CHANGE = '@@router/LOCATION_CHANGE'; // hardcoded to save 9KB lib link just to get one value
let requestMap = [];
let requestStack = [];
let requestTimer = undefined;

const createHeaders = () => {
    return {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': getLanguage(),
        'Authorization': getAuthorization(true)
    };
};

// GET | POST | PUT | DELETE must be raw functions to allow context propagation
const apiGet = function (url, params, onSuccess, onFail, always) {
    queueApiCall.call(this, url, params, null, 'GET', onSuccess, onFail, always)
};
const apiPost = function (url, params, data, onSuccess, onFail, always) {
    queueApiCall.call(this, url, params, data, 'POST', onSuccess, onFail, always)
};
const apiPut = function (url, params, data, onSuccess, onFail, always) {
    queueApiCall.call(this, url, params, data, 'PUT', onSuccess, onFail, always)
};
const apiDelete = function (url, params, data, onSuccess, onFail, always) {
    queueApiCall.call(this, url, params, data, 'DELETE', onSuccess, onFail, always)
};

const queueApiCall = function (url, params, data, method, onSuccess, onFail, always) {
    requestStack.push({ url, params, data, method, onSuccess, onFail, always, context: this });
    if (requestTimer === undefined) {
        requestTimer = setTimeout(() => processQueue(), 0);
    }
};

const processQueue = function () {
    if (requestStack.length === 0) {
        clearTimeout(requestTimer);
        requestTimer = undefined;
        writeDebug('Queue empty');
        return;
    }
    const item = requestStack.pop();
    writeDebug(`Queue item [${item.url}] processing begin`);

    apiFetch.call(item.context, item.url, item.params, item.data, item.method, item.onSuccess, item.onFail, () => {
        if (isFunction(item.always)) {
            item.always.call(item.context);
        }
        writeDebug(`Queue item [${item.url}] processing finished`);
        requestTimer = setTimeout(() => processQueue(), 0);
    });

    try {
        // if we have still valid token run in parallel
        if (Date.parse(JSON.parse(localStorage.getItem('auth')).expires) > (Date.now() + 30 * 1000)) {
            writeDebug('Queue parallel execution');
            requestTimer = setTimeout(() => processQueue(), 0);
        }
    } catch (_) { }
}

const apiFetch = function (url, params, data, method, onSuccess, onFail, always) {
    writeDebug("fetch - " + method + " - " + url);
    let config = { method: method, headers: createHeaders(), cache: "no-cache", url, paramsSerializer: createQs };
    if (data !== null && data !== undefined) {
        config.data = JSON.stringify(data);
    }

    const t = Date.now();
    requestMap[generateRequestMapKey(url, params)] = t;
    config.params = Object.assign({}, params, { t });
    config.hasOnSuccess = isFunction(onSuccess);
    config.hasOnFail = isFunction(onFail);
    config.hasAlways = isFunction(always);

    const ctx = this;
    axios.request(config)
        .then(res => {
            try {
                const tt = requestMap[generateRequestMapKey(url, params)];
                const ttt = parseInt(res.headers['x-timestamp'], 10) || Number.MAX_SAFE_INTEGER;
                if (ttt >= tt && isFunction(onSuccess)) {
                    onSuccess.call(ctx, res.data);
                }
            } catch (error) {
                writeErr('API request result processing failed', error)
                if (isFunction(onFail)) {
                    onFail.call(ctx, error);
                }
            }
        })
        .catch(err => {
            if (isFunction(onFail)) {
                onFail.call(ctx, err.response);
            }
        })
        .then(() => {
            if (isFunction(always)) {
                always.call(ctx);
            }
        });
};

const generateRequestMapKey = function (url, params) {
    return url + (params ? createQs(params) : '');
}

const isFunction = function (obj) {
    return !!(obj && obj.constructor && obj.call && obj.apply);
}

const parseQuery = function (url) {
    let params = {};
    const parts = url.split(/[?]/);
    if (parts.length !== 2) {
        return params;
    }

    let arr = parts[1].split(/&|=/);
    for (let i = 0; i < arr.length; i += 2) {
        params[arr[i]] = decodeURIComponent(arr[i + 1]);
    }
    return params
};

const createQs = function (obj) {
    const keys = Object.keys(obj);
    return keys.map(key => {
        const value = obj[key];

        if (value === undefined || value === null) {
            return '';
        }

        return encodeURI(key) + '=' + encodeURI(value);
    }).filter(x => x.length > 0).join('&');
}

const setupInterceptors = (store, history) => {
    const interceptor = axios.interceptors.response.use(response => response, error => {
        const auth = JSON.parse(localStorage.getItem('auth'));
        if (error.response.status === 401) {
            if (auth !== null && auth !== undefined && Date.parse(auth.expires) <= Date.now()) {
                axios.interceptors.response.eject(interceptor);
                return axios.post(getHostApi() + '/api/account/login',
                    { grant_type: 'refresh_token', refresh_token: auth.refreshToken },
                    { headers: createHeaders(), cache: "no-cache" })
                    .then(respo => {
                        if (respo.data.data.success) {
                            store.dispatch({ type: 'USER_LOGIN', data: respo.data.data });
                            error.response.config.headers['Authorization'] = getAuthorization(true);
                            return axios.request(error.response.config);
                        } else {
                            store.dispatch({ type: 'LOGIN_EXPIRED' });
                            if (!isOnPath({ prefix: '/login' })) {
                                history.push(`/login?returnUrl=${encodeURIComponent(window.location.pathname + window.location.search)}`);
                            } else {
                                store.dispatch({ type: LOCATION_CHANGE, payload: { pathname: '/login', hash: '', search: '' } });
                            }
                            return Promise.reject(error);
                        }
                    }).catch(error => {
                        if (isObjectPathDefined(error, 'response.data.error.message')) {
                            store.dispatch({ type: errorType, message: error.response.data.error.message });
                        }
                        if (!isOnPath({ prefix: '/login' })) {
                            history.push(`/login?returnUrl=${encodeURIComponent(window.location.pathname + window.location.search)}`);
                        } else {
                            store.dispatch({ type: LOCATION_CHANGE, payload: { pathname: '/login', hash: '', search: '' } });
                        }
                        return Promise.reject(error);
                    }).finally(() => setupInterceptors(store, history));
            } else {
                store.dispatch({ type: 'LOGIN_EXPIRED' });
                if (!isOnPath({ prefix: '/login' }) && error.config.url !== getHostApi() + '/api/account/notifications') {
                    store.dispatch(push(`/login?returnUrl=${encodeURIComponent(window.location.pathname + window.location.search)}`));
                }
                return Promise.reject(error);
            }
        } else if (error.response.status === 403) {
            if (isObjectPathDefined(error, 'response.data.error.message') && !error.config.hasOnFail) {
                store.dispatch({ type: errorType, message: error.response.data.error.message });
            }
        } else if (error.response.status === 404) {

        } else {
            if ((error.config.url !== getHostApi() + '/api/account/notifications') && !error.config.hasOnFail)
                store.dispatch({ type: errorType, message: "UnhappyUser.InternalServerError" });
        }
        return Promise.reject(error);
    });
};

const setupAuthWatch = (store, history) => {
    let auth = localStorage.getItem('auth');
    if ((auth === null || auth === undefined) && !store.getState().userContext.authentication.loading) {
        if (!isOnPath({ prefix: '/login' })) {
            writeDebug('Authentication expired, dispatching [LOGIN_EXPIRED] event')
            store.dispatch({ type: 'LOGIN_EXPIRED' })
        }
        setTimeout(() => setupAuthWatch(store, history), 1000);
        return;
    }
    auth = JSON.parse(auth);
    const authDefined = auth !== null && auth !== undefined && auth.expires !== null && auth.expires !== undefined;
    if (authDefined) {
        const timeDiff = (auth.requestTime === undefined || auth.requestTime === null || auth.issuedOn === undefined || auth.issuedOn === null) ? 0 : new Date(auth.requestTime) - new Date(auth.issuedOn);
        const tokenExpires = Date.parse(auth.expires) <= (Date.now() - timeDiff);
        const refreshExpires = auth.refreshExpires === undefined || (auth.refreshExpires !== undefined && Date.parse(auth.refreshExpires) <= (Date.now() - timeDiff));
        if (tokenExpires && refreshExpires && !isOnPath({ prefix: '/login' })) {
            writeDebug('Authentication expired, dispatching [LOGIN_EXPIRED] event')
            store.dispatch({ type: 'LOGIN_EXPIRED' });
        }
    } else {
        if (!isOnPath({ prefix: '/login' })) {
            writeDebug('Authentication object missing, dispatching [LOGIN_EXPIRED] event')
            store.dispatch({ type: 'LOGIN_EXPIRED' });
        }
    }
    setTimeout(() => setupAuthWatch(store, history), 1000);
}

const isClickHit = (holder, event) => {
    const offset = getAbsoluteOffset(holder);
    const result = offset.top <= event.pageY &&
        event.pageY <= (offset.top + holder.offsetHeight) &&
        offset.left <= event.pageX &&
        event.pageX <= (offset.left + holder.offsetWidth);

    writeDebug('Click hit detection', { offset, event: { pageX: event.pageX, pageY: event.pageY, event }, result, holder });
    return result;
}

const getAbsoluteOffset = function (element) {
    const body = document.body.getBoundingClientRect()
    const holder = element.getBoundingClientRect();
    return { top: holder.top - body.top, left: holder.left - body.left, width: holder.width, height: holder.height };
}

const setupResizeDispatcher = function (store) {
    window.onresize = function (evnt) {
        store.dispatch({ type: 'WINDOW_RESIZE', event: evnt });
    };
}

const isObjectPathDefined = function (object, path) {
    if (object === null || object === undefined) {
        return false;
    }
    let o = object
    const p = path.split(/[.]/);
    for (let i = 0; i < p.length; i++) {
        if (o === null || o === undefined || !o.hasOwnProperty(p[i])) {
            return false;
        }
        o = o[p[i]];
    }
    return o !== null && o !== undefined;
}

const getHostUrl = function () {
    return window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '')
}

const getHostApi = function () {
    return window.babylonApiUrl;
}

const hasPermission = function (user, permission) {
    if (user === null || user === undefined) {
        user = { authentication: JSON.parse(localStorage.getItem('auth')) };
    }
    if (permission === null || permission === undefined)
        return true;
    if (isString(permission) && isObjectPathDefined(user, "authentication.permissions") && user.authentication.permissions.length) {
        const phash = sha1(permission.toUpperCase()).toString().toUpperCase();
        if (user.authentication.permissions.indexOf(phash) !== -1) {
            return true;
        }
    }
    return false;
}

const getAgentLanguage = function (user) {
    try {
        if (user === null || user === undefined)
            user = { authentication: JSON.parse(localStorage.getItem('auth')) };
        return user.authentication.profile.agentLanguage;
    } catch (ex) {
        writeErr(ex);
        return null;
    }
}

const isOnPath = function (config) {
    const { prefix, regex } = config;
    let result;

    if (regex !== null && regex !== undefined) {
        result = regex.test(window.location.pathname);
    }

    if (prefix !== null && prefix !== undefined) {
        const ix = window.location.pathname.indexOf(prefix);
        result = ix >= 0 && ix <= 1;
    }
    writeDebug('Path [' + window.location.pathname + window.location.search + '] evaluated [' + result + '] with config:', config)
    return result;
}

const generateId = function () {
    var S4 = function () {
        return getRandomValue().toString(16).slice(-4);
    };
    return ('_' + S4() + S4() + S4() + S4() + S4() + S4() + S4() + S4());
}

const processError = function (err, defaultValue = undefined) {
    return isObjectPathDefined(err, 'data.error') && typeof err.data.error === 'object' && err.data.error !== null
        && Object.keys(err.data.error).length > 0 ? err.data.error : defaultValue;
}

const processErrorMessage = function (err, defaultMessage = "UnhappyUser.InternalServerError") {
    return isObjectPathDefined(err, 'data.error.message') && (err.data.error.message.startsWith('UnhappyUser.') && err.data.error.message !== 'UnhappyUser.InternalServerError') ? err.data.error.message : defaultMessage;
}

const processErrorData = function (err, defaultValue = undefined) {
    return isObjectPathDefined(err, 'data.error.messageData') && typeof err.data.error.messageData === 'object' && err.data.error.messageData !== null
        && Object.keys(err.data.error.messageData).length > 0 ? err.data.error.messageData : defaultValue;
}

export {
    apiGet, apiPost, apiPut, apiDelete, isOnPath, getLanguage, parseQuery, createQs, setupInterceptors, setupAuthWatch,
    isObjectPathDefined, getAuthorization, getHostUrl, getHostApi, hasPermission, getAgentLanguage,
    setupResizeDispatcher, getMomentLanguage, generateId, isClickHit, processError, processErrorMessage, processErrorData
};