const LOCATION_CHANGE = '@@router/LOCATION_CHANGE'; // hardcoded to save 9KB lib link just to get one value
export const errorType = 'MAKE_USER_UNHAPPY';

const initialState = {
    error: null,
    errorData: null
};

// store reducer
export const reducer = (state, action) => {
    state = state || initialState;
    switch(action.type) {
        case LOCATION_CHANGE:
            return { ...state, location: action.payload.pathname, error: null, errorData: null };
        case errorType:
            return { ...state, error: action.message, errorData: action.messageData };
        default:
            return state;
    }
}
