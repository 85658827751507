import 'react-app-polyfill/ie11';
import './function-fixes.js';
//packages
import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import { AppLoadingScreen } from './appLoadingScreen';

// app js
const AppLoader = React.lazy(() => import('./appLoader'));

window.addEventListener('DOMContentLoaded', () => {

    // Opera 8.0+
    const isOpera = (!!window.opr && !!window['opr']['addons']) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    // Firefox 1.0+
    const isFirefox = typeof InstallTrigger !== 'undefined';
    // Safari 3.0+ "[object HTMLElementConstructor]" 
    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof window['safari'] !== 'undefined' && window['safari'].pushNotification));
    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/false || !!document.documentMode;
    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;
    // Chrome 1 - 79
    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    // Edge (based on chromium) detection
    const isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);
    // Blink engine detection
    const isBlink = (isChrome || isOpera) && !!window.CSS;
    // Detect any mobile browser
    const isMobile = /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i.test(navigator.userAgent) || window.innerWidth <= 574;

    // store in window object for later use
    window._browser = { isOpera, isFirefox, isSafari, isIE, isEdge, isChrome, isEdgeChromium, isBlink, isMobile }

    // add class to body to allow IE specific css
    isOpera && (document.body.className = document.body.className + ' opera');
    isFirefox && (document.body.className = document.body.className + ' firefox');
    isSafari && (document.body.className = document.body.className + ' safari');
    isIE && (document.body.className = document.body.className + ' ie');
    isEdge && (document.body.className = document.body.className + ' edge');
    isChrome && (document.body.className = document.body.className + ' chrome');
    isEdgeChromium && (document.body.className = document.body.className + ' chromium');
    isBlink && (document.body.className = document.body.className + ' blink');
    isMobile && (document.body.className = document.body.className + ' is-mobile');
});

ReactDOM.render(
    <>
        <noscript>
            You need to enable JavaScript to run this app.
        </noscript>
        <React.Suspense fallback={<AppLoadingScreen/>}>
            <AppLoader />
        </React.Suspense>
    </>, document.getElementById('root')
);

registerServiceWorker();