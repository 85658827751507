import React from 'react';
import './loadingComponent.scss';
import Buble from '../../img/bublegum.gif';
import Ping from '../../img/ping-pong.gif';
import BirdAnimated from "../../img/icons/leftPanel/BirdAnimated";


const Loading = ({ noText, position = "relative" }) => {
    return (
        <div className='bb-loader' style={{ position: position }}>
            <div className='loader--dot'></div>
            <div className='loader--dot'></div>
            <div className='loader--dot'></div>
            <div className='loader--dot'></div>
            <div className='loader--dot'></div>
            <div className='loader--dot'></div>
            {!noText && <div className='loader--text'></div>}
        </div>
    );
}

const BubleHead = () => {
    return (
        <div className="load-img-wrapper"><img alt="" src={Buble}></img></div>
    );
}
const PingPong = () => {
    return (
        <div className="load-img-wrapper"><img alt="" src={Ping}></img></div>
    );
}

const Circles = ({ useLoginColor }) => {
    if(useLoginColor) {
        return (
            <div className="loader-new-root">
                <div className='loader-new use-login-color' data-theme={localStorage.getItem("locales_theme")} data-login={localStorage.getItem("locales_login")} />
            </div>
        );
    }
    return (
        <div style={{lignItems: 'center', display: 'flex', flex: '1 0 auto'}}>
            <div className="loader-new-root">
                <div className='loader-new' data-theme={localStorage.getItem("locales_theme")} data-login={localStorage.getItem("locales_login")} />
            </div>
        </div>
    )
}

const Bird = () => {
    return (
        <div className='animated'>
            <BirdAnimated />
        </div>

    )
}
const JumpingBeans = () => {
    return (
        <div className="jumping-beans">
            <div className="first" />
            <div className="second" />
            <div className="third" />
        </div>
    )
}

export {
    Loading,
    BubleHead,
    PingPong,
    Circles,
    Bird,
    JumpingBeans
};