import React from 'react';
import { Circles } from './components/common/loadingComponent';
import { getLanguage, isRepiWeb } from './store/storeFunctions';

import criftimelinetrees from './img/CrifTimelineTrees.svg';
import cribislogograyCz from './img/Cribis.cz_grey.svg';
import cribislogograySk from './img/Cribis.sk_grey.svg';
import cribislogowhiteCz from './img/Cribis.cz_white.svg';
import cribislogowhiteSk from './img/Cribis.sk_white.svg';

import RepiCzWhite from './img/repi/RepiCzWhite.svg';
import RepiSkWhite from './img/repi/RepiSkWhite.svg';
import RepiCzGrey from './img/repi/RepiCzGrey.svg';
import RepiSkGrey from './img/repi/RepiSkGrey.svg';

import './appLoadingScreen.scss';
import './root.scss';
import './components/common/loadingComponent.scss';

export const AppLoadingScreen = () => {
    return (<>
        {localStorage.locales_login === 'blue' && (
            <div className='app-screen app-loading-screen blue-background'>
                {isRepiWeb()
                    ? <img className="cribis-logo" alt="" src={getLanguage() === 'sk-SK' ? RepiSkWhite : RepiCzWhite}></img>
                    : <img className="cribis-logo" alt="" src={getLanguage() === 'sk-SK' ? cribislogowhiteSk : cribislogowhiteCz}></img>}
                <Circles useLoginColor/>
            </div>
        )}
        {localStorage.locales_login === 'tree' && (
            <div>
                <div className="app-loading-screen">
                    {isRepiWeb()
                        ? <img className="cribis-logo" alt="" src={getLanguage() === 'sk-SK' ? RepiSkGrey : RepiCzGrey}></img>
                        : <img className="cribis-logo" alt="" src={getLanguage() === 'sk-SK' ? cribislogograySk : cribislogograyCz}></img>}
                    <Circles useLoginColor/>
                </div>
                <div className="cribis-tree">
                    <img alt="" src={criftimelinetrees} style={{ position: "absolute", bottom: "12vh", width: "100vw", opacity: "0.15" }}></img>
                </div>
            </div>
        )}
    </>)
};